import React from 'react';
import ServicesSidebar from './ServicesSidebar';

class SectorDetails_3 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full small-device  p-t80 p-b50 bg-white">
                    <div className="section-content">
                        <div className="container">
                            <div className="row">
                                <ServicesSidebar />
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">
                                        {/* TITLE START */}
                                        <div className="text-left">
                                            <h2 className="wt-title">Arc Air Gouging</h2>
                                        </div>
                                        {/* TITLE END */}
                                        <div className="wt-box services-etc m-b30">
                                            <p>The heat from a carbon arc is used in air arc gouging, which involves metal removal. A carbon or graphite electrode, some compressed air, and regular electricity are all used in this method. The electrode-metal workpiece heat arc melts and gouges the workpiece. The molten metal is also blown away by the compressed air. This method often cuts metals, including stainless steel, carbon steel, and alloys of copper, aluminum, brass, and magnesium.</p>
                                            <div className="wt-media m-b30">
                                                <img src={require('./../../images/services/large/pic8.jpg').default} alt=""/>
                                            </div>
                                            <div className="wt-info">
                                                <p>A great deal of fume (metal vapor, carbon dust, and metallic byproducts) is produced because the elements of the molten metal react violently with air. The intensity of the air blast also tends to evaporate most of the molten metal into small droplets. An air-arc gouging operation often produces more toxic fumes than a business can legally allow its employees to be exposed to. Other risks may be associated with gouging, such as exposure to certain toxins that are components of the base metal. Hence, it's clear that a reliable method of fume extraction must be put into place.</p>
                                                <blockquote className="m-a0 bg-gray p-a30">
                                                    <i className="fa fa-quote-left font-20" />
                                                    <p>An air-arc gouging operation often produces more toxic fumes than a business can legally allow its employees to be exposed to.
                    </p>
                                                    <div className="p-t15 text-uppercase">
                                                        <strong></strong>
                                                        <span></span>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="service-provide">
                                            <h2>System Benefits</h2>
                                            <p>To protect workers, source capture ventilation devices such as our portable fume extractors draw fumes in and away from the operators' breathing zones. Indoor air is not contaminated.</p>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-angle-right">
                                                        <li>Reduce Absenteeism and Turnover</li>
                                                        <li>Increase Productivity and Accuracy</li>
                                                        <li>Prevent Contaminant Buildup on Surrounding Equipment</li>
                                                        <li>Meet Government Regulations</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="wt-media m-b30">
                                                        <img src={require('./../../images/gallery/pic3.jpg').default} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div>
                                            <h2>Research</h2>
                                            <div className="wt-accordion acc-bg-gray" id="accordion5">
                                                <div className="panel wt-panel">
                                                    <div className="acod-head acc-actives">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                                What problems we face?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseOne5" className="acod-body collapse show bg-gray">
                                                        <div className="acod-content p-a15">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, injected humour, or randomised words which don't look even slightly believablThere are many variations of passages.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                                Was our solution best?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseTwo5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                                Was our client satisfy?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseThree5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Developement lorem Ipsum is simply dummy text of the printing and type has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default SectorDetails_3;