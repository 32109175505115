import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: '01',
        flaticon: 'flaticon-conveyor',
        title1: 'Production',
        title2: 'Welding',
        link: '/Sector_Production_Welding',
        description: 'Commonly used in manufacturing and construction industries.'
    },
    {
        count: '02',
        flaticon: 'flaticon-stamp',
        title1: 'Bench',
        title2: 'Welding',
        link: '/Sector_Bench_Welding',
        description: 'Shield your lungs from harmful vapors and fumes while working on the bench.'
    },
    {
        count: '03',
        flaticon: 'flaticon-stamp',
        title1: 'Arc Air',
        title2: 'Gouging',
        link: '/Sector_Arc_Air_Gouging',
        description: 'An air-arc gouging operation often produces more toxic fumes.'
    },
    {
        count: '04',
        flaticon: 'flaticon-robotic-arm',
        title1: 'Plasma Cutting',
        title2: '',
        link: '/Sector_Plasma_Cutting',
        description: 'Extraction of fumes produced by plasma cutters is very necessary.'
    },
    {
        count: '05',
        flaticon: 'flaticon-industry',
        title1: 'Chemical Industry',
        title2: '',
        link: '/Sector_Chemical_Industry',
        description: 'Chemicals directly threaten individuals via touch or inhalation as dust or fume.'
    },
    {
        count: '06',
        flaticon: 'flaticon-healthcare-and-medical',
        title1: 'Laboratory',
        title2: '',
        link: '/Sector_Laboratory',
        description: 'Extraction systems may be required in a lab where hazardous chemicals are used.'
    },
]

var bnr1 = require('./../../images/background/bg-7.jpg');

class Services13 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b40 bg-no-repeat bg-bottom-right bg-cover" style={{ backgroundImage: "url(" + bnr1.default + ")" }}>
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START*/}
                            <div className="section-head center wt-small-separator-outer text-center">
                                <div className="wt-small-separator site-text-primary">
                                    <div className="sep-leaf-left" />
                                    <div>The Best Fume Extraction</div>
                                    <div className="sep-leaf-right" />
                                </div>
                                <h2>High Performance Fume Extraction For Multiple Industries!</h2>
                            </div>
                            {/* TITLE END*/}
                            <div className="row d-flex justify-content-center">
                                {services.map((item, index) => (
                                    <div key={index} className="col-lg-4 col-md-6 col-sm-12 m-b50">
                                        <div className="service-border-box">
                                            <div className="wt-box service-box-1 bg-white">
                                                <div className="service-box-title title-style-2 site-text-secondry">
                                                    <span className="s-title-one">{item.title1}</span>
                                                    <span className="s-title-two">{item.title2}</span>
                                                </div>
                                                <div className="service-box-content">
                                                    <p>{item.description}</p>
                                                    <NavLink to={item.link} className="site-button-link">Read More</NavLink>
                                                </div>
                                                <div className="wt-icon-box-wraper">
                                                    <div className="wt-icon-box-md site-bg-primary">
                                                        <span className="icon-cell text-white"><i className={item.flaticon} /></span>
                                                    </div>
                                                    <div className="wt-icon-number"><span>{item.count}</span></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Services13;