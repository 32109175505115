import React from 'react';
import ServicesSidebar from './ServicesSidebar';

class SectorDetails_2 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full small-device  p-t80 p-b50 bg-white">
                    <div className="section-content">
                        <div className="container">
                            <div className="row">
                                <ServicesSidebar />
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">
                                        {/* TITLE START */}
                                        <div className="text-left">
                                            <h2 className="wt-title">Bench Welding</h2>
                                        </div>
                                        {/* TITLE END */}
                                        <div className="wt-box services-etc m-b30">
                                            <p>A waist-high table designed for use as a workbench is known as a welding table. When you are engaged in the metal manufacturing industry with welding, you will employ it. A steady work surface is essential when welding, and a welding table may help with measuring and squaring, making it a valuable tool. A welding table's dimensions are typically between 20 by 40 inches and 6 by 13 inches. Regardless of their dimensions, all welding tables are constructed from durable steel that can resist the high temperatures and constant use inherent to the welding process.</p>
                                            <div className="wt-media m-b30">
                                                <img src={require('./../../images/services/large/pic8.jpg').default} alt=""/>
                                            </div>
                                            <div className="wt-info">
                                                <p>Make sure you're in a well-ventilated environment if you're going to be using a welding table. This shields your lungs from harmful vapors and fumes. In situations when enough ventilation is not available, however, sophisticated fume extraction solutions may be of great assistance. Wearing dry leather gloves will also reduce your susceptibility to electric shock.</p>
                                                <blockquote className="m-a0 bg-gray p-a30">
                                                    <i className="fa fa-quote-left font-20" />
                                                    <p>sophisticated fume extraction solutions may be of great assistance.
                    </p>
                                                    <div className="p-t15 text-uppercase">
                                                        <strong></strong>
                                                        <span></span>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="service-provide">
                                            <h2>System Benefits</h2>
                                            <p>To protect workers, source capture ventilation devices such as our portable fume extractors draw fumes in and away from the operators' breathing zones. Indoor air is not contaminated.</p>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-angle-right">
                                                        <li>Reduce Absenteeism and Turnover</li>
                                                        <li>Increase Productivity and Accuracy</li>
                                                        <li>Prevent Contaminant Buildup on Surrounding Equipment</li>
                                                        <li>Meet Government Regulations</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="wt-media m-b30">
                                                        <img src={require('./../../images/gallery/pic3.jpg').default} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div>
                                            <h2>Research</h2>
                                            <div className="wt-accordion acc-bg-gray" id="accordion5">
                                                <div className="panel wt-panel">
                                                    <div className="acod-head acc-actives">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                                What problems we face?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseOne5" className="acod-body collapse show bg-gray">
                                                        <div className="acod-content p-a15">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, injected humour, or randomised words which don't look even slightly believablThere are many variations of passages.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                                Was our solution best?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseTwo5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                                Was our client satisfy?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseThree5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Developement lorem Ipsum is simply dummy text of the printing and type has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default SectorDetails_2;