import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Sectors from './Pages/Sectors';
import Sector_Production_Welding from './Pages/Sector_Production_Welding';
import Sector_Bench_Welding from './Pages/Sector_Bench_Welding';
import Sector_Arc_Air_Gouging from './Pages/Sector_Arc_Air_Gouging';
import Sector_Plasma_Cutting from './Pages/Sector_Plasma_Cutting';
import Sector_Chemical_Industry from './Pages/Sector_Chemical_Industry';
import Sector_Laboratory from './Pages/Sector_Laboratory';
import Shop from './Pages/Shop';
import ShopFumeExtractors from './Pages/ShopFumeExtractors';
import ShopDetailESESLV from './Pages/ShopDetailESESLV';
import ShopDetailESEHV from './Pages/ShopDetailESEHV';
import ShopDetailMAC from './Pages/ShopDetailMAC';
import ShopDetailIEAC from './Pages/ShopDetailIEAC';
import ShopAirCleaners from './Pages/ShopAirCleaners';
import ShopFilters from './Pages/ShopFilters';
import ShopAccessories from './Pages/ShopAccessories';
import ShopParts from './Pages/ShopParts';
import ShopDetail from './Pages/ShopDetail';
import BlogGrid from './Pages/BlogGrid';
import BlogList from './Pages/BlogList';
import Difference from './Pages/Difference';
import EDU1 from './Pages/EDU1';
import Faq from './Pages/Faq';
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';
import Error403 from './Pages/Error403';
import Error404 from './Pages/Error404';
import Error405 from './Pages/Error405';
import ContactUs from './Pages/ContactUs';


class Components extends React.Component {
    render() {
        return (
            <BrowserRouter basename="/">
                <div className="page-wraper">
                        <Switch>
                            <Route path='/' exact component={Home} />
                            <Route path='/home' exact component={Home} />
                            <Route path='/about' exact component={About} />
                            <Route path='/Sectors' exact component={Sectors} />
                            <Route path='/Sector_Production_Welding' exact component={Sector_Production_Welding} />
                            <Route path='/Sector_Bench_Welding' exact component={Sector_Bench_Welding} />
                            <Route path='/Sector_Arc_Air_Gouging' exact component={Sector_Arc_Air_Gouging} />
                            <Route path='/Sector_Plasma_Cutting' exact component={Sector_Plasma_Cutting} />
                            <Route path='/Sector_Chemical_Industry' exact component={Sector_Chemical_Industry} />
                            <Route path='/Sector_Laboratory' exact component={Sector_Laboratory} />
                            <Route path='/shop' exact component={Shop} />
                            <Route path='/ShopFumeExtractors' exact component={ShopFumeExtractors} />
                            <Route path='/ShopDetailESESLV' exact component={ShopDetailESESLV} />
                            <Route path='/ShopDetailESEHV' exact component={ShopDetailESEHV} />
                            <Route path='/ShopDetailMAC' exact component={ShopDetailMAC} />
                            <Route path='/ShopDetailIEAC' exact component={ShopDetailIEAC} />
                            <Route path='/ShopFilters' exact component={ShopFilters} />
                            <Route path='/ShopAirCleaners' exact component={ShopAirCleaners} />
                            <Route path='/ShopAccessories' exact component={ShopAccessories} />
                            <Route path='/ShopParts' exact component={ShopParts} />
                            <Route path='/shop-detail' exact component={ShopDetail} />
                            <Route path='/blog-grid' exact component={BlogGrid} />
                            <Route path='/blog-list' exact component={BlogList} />
                            <Route path='/difference' exact component={Difference} />
                            <Route path='/EDU1' exact component={EDU1} />
                            <Route path='/faq' exact component={Faq} />
                            <Route path='/terms' exact component={Terms} />
                            <Route path='/privacy' exact component={Privacy} />
                            <Route path='/error403' exact component={Error403} />
                            <Route path='/error404' exact component={Error404} />
                            <Route path='/error405' exact component={Error405} />
                           
                            <Route path='/contactus' exact component={ContactUs} />
                            
                            <Route component={Error} />
                        </Switch>
                </div>
            </BrowserRouter>
        );
    };
};

export default Components;