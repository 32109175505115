import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Parts/Header10';
import Footer from './../Parts/Footer3';
import Banner from './../Segments/Banner';
import FeaturedProducts from './../Segments/FeaturedProducts';
import ImageGallery from 'react-image-gallery';
import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

const images = [
    {
        original: require('./../../images/products/pic-3.jpg').default,
        thumbnail: require('./../../images/products/thumb/pic1.jpg').default,
    },
    {
        original: require('./../../images/products/pic-3.jpg').default,
        thumbnail: require('./../../images/products/thumb/pic2.jpg').default,
    }
];

var bnrimg = require('./../../images/banner/5.jpg');

class ShopDetailMAC extends React.Component {
    renderRightNav = (onClick, disabled) => {
        return (
            <button
              className='image-gallery-icon image-gallery-right-nav owl-next'
              onClick={onClick}
             ><i class="fa fa-chevron-right"></i></button>
          )
    };

    renderLeftNav = (onClick, disabled) => {
        return (
            <button
              className='image-gallery-icon image-gallery-left-nav owl-prev'
              onClick={onClick}
             ><i class="fa fa-chevron-left"></i></button>
          )
    };
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="Product Detail" pagename="ENVIROMAC™ Portable" bgimage={bnrimg.default}/>
                    {/* INNER PAGE BANNER END */}
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-t80 p-b80">
                        {/* PRODUCT DETAILS */}
                        <div className="container woo-entry">
                            <div className="row m-b30">
                                <div className="col-lg-3 col-md-4  m-b30">
                                    <div className="wt-box wt-product-gallery on-show-slider">
                                    <ImageGallery renderLeftNav={this.renderLeftNav} renderRightNav={this.renderRightNav} items={images} showPlayButton={false} />
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-8">
                                    <div className="product-detail-info bg-gray p-a30">
                                        <div className="wt-product-title ">
                                            <h2 className="post-title"><NavLink to={"#"}>ENVIROMAC™ Portable Smoke/Fume Extractor and Mobile Air Cleaner</NavLink></h2>
                                        </div>
                                        {/*<h3 className="m-tb10">$99.00 </h3>*/}
                                        {/*<div className="m-b15">
                                            <span className="rating-bx">
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star-o" />
                                            </span>
                                            <span className="font-weight-600 text-black">(4 customer reviews)</span>
                                        </div>*/}
                                        <div className="wt-product-text">
                                            <p>The ENVIROMAC™ Portable Welding Smoke Extractor is ideal for portable and fixed welding, dust and oil mist applications. The contaminant is trapped at the source, thus maximizing the protection to the operator by removing contaminant before it enters the operators breathing area. The unit recycles clean air back into the building thus eliminating additional heating or cooling costs.</p>
                                        </div>
                                        <form method="post" className="cart clearfix ">
                                            <button type="button" className="site-button-secondry m-r10 site-btn-effect m-b20"><i className="fa fa-shopping-bag" /> Request Quote</button>

                                        </form>
                                        <div className="product_meta">
                                            <span className="sku_wrapper">Models:
                <span className="sku"> MAC1 / MAC2</span>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* TABS CONTENT START */}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="wt-tabs border bg-tabs">
                                        <ul className="nav nav-tabs">
                                            <li><a data-toggle="tab" href="#web-design-19" className="active">Description</a></li>
                                            <li><a data-toggle="tab" href="#graphic-design-19">Specification</a></li>
                                            {/*<li><a data-toggle="tab" href="#developement-19">Review</a></li>*/}
                                        </ul>
                                        <div className="tab-content">
                                            <div id="web-design-19" className="tab-pane active">
                                                <div className=" p-a10">
                                                    <p className="m-b10">Ideal for smoke and fume extraction in the welding and manufacturing industries, this mobile fume extractor is equipped with the following features:</p>
                                                    <ul className="site-list-style-two m-b0">
                                                        <li>High efficiency washable filter and activated carbon in both doors. There is also an optional throwaway paper filter or a 99.997% HEPA filter.</li>
                                                        <li>Portability and flexibility to accommodate use in hard to reach places</li>
                                                        <li>The Snorkvac™ Fume Extractor Snorkel Arm which is 10ft.(3M) long and 6"(160MM) wide. The Snorkvac™ Fume Arm is uniquely designed and externally supported to minimize the restriction to the air flow and minimizing any pressure drop. This makes adjustments and hose replacements much easier than an internally supported arm.</li>
                                                        <li>Air diverter increases capture distance and gives uniform air flow</li>
                                                        <li>Standalone adjustable contactor overload to maximize the protection to the motor. The top panel (Electrical Control Panel) of the machine allows easy access to the electrical components.</li>
                                                        <li>Ease of movement with 2 – 5” fixed casters and 2 – 5” rotating casters with a brake.</li>
                                                        <li>Units are of sturdy construction and flame resistant as they are manufactured out of 14 gauge cold rolled steel and painted with a baked on powder coat.</li>
                                                        <li>Simple operation provides a safer work environment for all team members.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="graphic-design-19" className="tab-pane">
                                                <table className="table m-b0 no-border">
                                                    <tbody>
                                                    <tr>
                                                        <th><b>Model</b></th>
                                                        <th>MAC 1</th>
                                                        <th>MAC 2</th>
                                                    </tr>
                                                        <tr>
                                                            <th><b>Voltage</b></th>
                                                            <td>120 Volts, 50/60 HZ</td>
                                                            <td>120 Volts, 50/60 HZ</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Amperage</b></td>
                                                            <td>17.5 amps</td>
                                                            <td>12 amps</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Motor (HP)</b></td>
                                                            <td>1.5 HP</td>
                                                            <td>1 HP</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Motor RPM</b></td>
                                                            <td>3450</td>
                                                            <td>3450</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Type of Motors</b></td>
                                                            <td>Direct Drive, TEFC</td>
                                                            <td>Direct Drive, TEFC</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Air Flow (at the hood)</b></td>
                                                            <td>1400 CFM (2379 m3/hr)</td>
                                                            <td>1050 CFM (1784 m3/hr)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Arm Length</b></td>
                                                            <td>10 ft (3M)</td>
                                                            <td>10 ft (3M)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Arm Diameter</b></td>
                                                            <td>6" (160 MM)</td>
                                                            <td>6" (160 MM)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Dimensions</b></td>
                                                            <td>H 41.75” X W 26” X D 30.25”</td>
                                                            <td>H 41.75” X W 26” X D 30.25”</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Weight</b></td>
                                                            <td>290 lbs. (132 KGS)</td>
                                                            <td>288 lbs. (131 KGS)</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div id="developement-19" className="tab-pane">
                                                <div className=" p-a20 bg-gray">
                                                    <div id="comments">
                                                        <ol className="commentlist">
                                                            <li className="comment">
                                                                <div className="comment_container">
                                                                    <img className="avatar avatar-60 photo" src={require('./../../images/testimonials/pic1.jpg').default} alt=""/>
                                                                    <div className="comment-text">
                                                                        <div className="star-rating">
                                                                            <div data-rating={3}>
                                                                                <i className="fa fa-star" data-alt={1} title="regular" />
                                                                                <i className="fa fa-star" data-alt={2} title="regular" />
                                                                                <i className="fa fa-star" data-alt={3} title="regular" />
                                                                                <i className="fa fa-star" data-alt={4} title="regular" />
                                                                                <i className="fa fa-star-o" data-alt={5} title="regular" />
                                                                            </div>
                                                                        </div>
                                                                        <p className="meta">
                                                                            <strong className="author">Cobus Bester</strong>
                                                                            <span><i className="fa fa-clock-o" /> March 20, 2020</span>
                                                                        </p>
                                                                        <div className="description">
                                                                            <p>Really happy with this print. The colors are great, and the paper quality is very good.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="comment">
                                                                <div className="comment_container">
                                                                    <img className="avatar avatar-60 photo" src={require('./../../images/testimonials/pic2.jpg').default} alt=""/>
                                                                    <div className="comment-text">
                                                                        <div className="star-rating">
                                                                            <div data-rating={3}>
                                                                                <i className="fa fa-star" data-alt={1} title="regular" />
                                                                                <i className="fa fa-star" data-alt={2} title="regular" />
                                                                                <i className="fa fa-star" data-alt={3} title="regular" />
                                                                                <i className="fa fa-star" data-alt={4} title="regular" />
                                                                                <i className="fa fa-star" data-alt={5} title="regular" />
                                                                            </div>
                                                                        </div>
                                                                        <p className="meta">
                                                                            <strong className="author">Cobus Bester</strong>
                                                                            <span><i className="fa fa-clock-o" /> March 12, 2020</span>
                                                                        </p>
                                                                        <div className="description">
                                                                            <p>Really happy with this print. The colors are great, and the paper quality is very good.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="comment">
                                                                <div className="comment_container">
                                                                    <img className="avatar avatar-60 photo" src={require('./../../images/testimonials/pic3.jpg').default} alt=""/>
                                                                    <div className="comment-text">
                                                                        <div className="star-rating">
                                                                            <div data-rating={3}>
                                                                                <i className="fa fa-star" data-alt={1} title="regular" />
                                                                                <i className="fa fa-star" data-alt={2} title="regular" />
                                                                                <i className="fa fa-star" data-alt={3} title="regular" />
                                                                                <i className="fa fa-star" data-alt={4} title="regular" />
                                                                                <i className="fa fa-star-o" data-alt={5} title="regular" />
                                                                            </div>
                                                                        </div>
                                                                        <p className="meta">
                                                                            <strong className="author">Cobus Bester</strong>
                                                                            <span><i className="fa fa-clock-o" /> March 11, 2020</span>
                                                                        </p>
                                                                        <div className="description">
                                                                            <p>Really happy with this print. The colors are great, and the paper quality is very good.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ol>
                                                    </div>
                                                    <div id="review_form_wrapper">
                                                        <div id="review_form">
                                                            <div id="respond" className="comment-respond">
                                                                <h3 className="comment-reply-title" id="reply-title">Add a review</h3>
                                                                <form className="comment-form" method="post" action="#">
                                                                    <div className="comment-form-author">
                                                                        <label>Name <span className="required">*</span></label>
                                                                        <input type="text" aria-required="true" size={30} defaultValue name="author" id="author" />
                                                                    </div>
                                                                    <div className="comment-form-email">
                                                                        <label>Email <span className="required">*</span></label>
                                                                        <input type="text" aria-required="true" size={30} defaultValue name="email" id="email" />
                                                                    </div>
                                                                    <div className="comment-form-rating">
                                                                        <label>Your Rating</label>
                                                                        <div className="star-Rating-input">
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star-o" />
                                                                            <i className="fa fa-star-o" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="comment-form-comment">
                                                                        <label>Your Review</label>
                                                                        <textarea aria-required="true" rows={8} cols={45} name="comment" id="comment" defaultValue={""} />
                                                                    </div>
                                                                    <div className="form-submit">
                                                                        <button type="button" className="site-button site-btn-effect">Submit</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* TABS CONTENT START */}
                        </div>
                        {/* PRODUCT DETAILS */}
                    </div>
                    {/* CONTENT CONTENT END */}
                    <FeaturedProducts />
                </div>
                <Footer />
            </>
        );
    };
};

export default ShopDetailMAC;