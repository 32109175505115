import React from 'react';
import Header from './../Parts/Header10';
import Footer from './../Parts/Footer3';
import Banner from './../Segments/Banner';
import SectorDetails5 from './../Segments/SectorDetails5';

var bnrimg = require('./../../images/banner/S_5.jpg');

class Sector_Chemical_Industry extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="Chemical Industry" pagename="Chemical Industry" bgimage={bnrimg.default}/>
                    {/* INNER PAGE BANNER END */}
                    <SectorDetails5 />
                </div>
                <Footer />
            </>
        );
    };
};

export default Sector_Chemical_Industry;