import React from 'react';
import Header from '../Parts/Header10';
import Footer from '../Parts/Footer3';
import Banner from '../Segments/Banner';
import Services13 from '../Segments/Services13';



var bnrimg = require('./../../images/banner/2.jpg');

class Sectors extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="Service Sectors" pagename="Service Sectors" bgimage={bnrimg.default}/>
                    {/* INNER PAGE BANNER END */}
                    <Services13 />
                 </div>
                <Footer />
            </>
        );
    };
};

export default Sectors;