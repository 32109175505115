import React from 'react';
import Header from './../Parts/Header10';
import Footer from './../Parts/Footer3';
import Banner from './../Segments/Banner';
import About from './../Segments/About';
import Services13 from './../Segments/Services13';



var bnrimg = require('./../../images/banner/3.jpg');

class About1 extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner title="About Us" pagename="About" bgimage={bnrimg.default}/>
                    <About />
                    <Services13 />

                </div>
                <Footer />
            </>
        );
    };
};

export default About1;