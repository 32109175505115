import React from 'react';
import Header from './../Parts/Header10';
import Footer from './../Parts/Footer3';
import Banner from './../Segments/Banner';
import SectorDetails3 from './../Segments/SectorDetails3';

var bnrimg = require('./../../images/banner/S_3.jpg');

class Sector_Arc_Air_Gouging extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="Arc Air Gouging" pagename="Arc Air Gouging" bgimage={bnrimg.default}/>
                    {/* INNER PAGE BANNER END */}
                    <SectorDetails3 />
                </div>
                <Footer />
            </>
        );
    };
};

export default Sector_Arc_Air_Gouging;