import React from 'react';
import { NavLink } from 'react-router-dom';


class ServicesSidebar extends React.Component {
    render() {
        return (
            <>
                <div className="col-lg-4 col-md-12 rightSidebar">
                    <div className="all_services">
                        <ul>
                            <li><NavLink to={"/Sectors"}>Service Sectors</NavLink></li>
                            <li><NavLink to={"/Sector_Production_Welding"}>Production Welding</NavLink></li>
                            <li><NavLink to={"/Sector_Bench_Welding"}>Bench Welding</NavLink></li>
                            <li><NavLink to={"/Sector_Arc_Air_Gouging"}>Arc Air Gouging</NavLink></li>
                            <li><NavLink to={"/Sector_Plasma_Cutting"}>Plasma Cutting</NavLink></li>
                            <li><NavLink to={"/Sector_Chemical_Industry"}>Chemical Industry</NavLink></li>
                            <li><NavLink to={"/Sector_Laboratory"}>Laboratory</NavLink></li>                            
                        </ul>
                    </div>

                </div>
            </>
        );
    }
};

export default ServicesSidebar;