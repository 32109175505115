import React from 'react';
import { NavLink } from 'react-router-dom';

const products = [
    {
        image: require('./../../images/products/pic-1.jpg'),
        title: 'ESE HandHeld Welding Smoke Extractor',
        title2: 'Standard Low Vacuum Models',
        link: '/ShopDetailESESLV',
        price: 'Request Quote'
    },
    {
        image: require('./../../images/products/pic-2.jpg'),
        title: 'ESE HandHeld Welding Smoke Extractor',
        title2: 'High Vacuum Models',
        link: '/ShopDetailESEHV',
        price: 'Request Quote'
    },
    {
        image: require('./../../images/products/pic-3.jpg'),
        title: 'ENVIROMAC™ Portable Smoke/Fume Extractors',
        title2: '',
        link: '/ShopDetailMAC',
        price: 'Request Quote'
    }
    
]

class ShopProducts extends React.Component {
    render() {
        return (
            <>
                <div className="row">
                    {products.map((item, index) => (
                        <div key={index} className="col-lg-6 col-md-6 m-b30">
                            <div className="wt-box wt-product-box block-shadow  overflow-hide">
                                <div className="wt-thum-bx wt-img-overlay1 wt-img-effect zoom">
                                    <img src={item.image.default} alt="" />
                                </div>
                                <div className="wt-info  text-center">
                                    <div className="p-a20 bg-white">
                                        <h3 className="wt-title">
                                            <NavLink to={item.link}>{item.title}  {item.title2}</NavLink>
                                        </h3>
                                        <span className="price">
                                            <ins>
                                                <span><span className="Price-currencySymbol"> </span>{item.price}</span>
                                            </ins>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    }
};

export default ShopProducts;