import React from 'react';
import ServicesSidebar from './ServicesSidebar';

class SectorDetails_1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full small-device  p-t80 p-b50 bg-white">
                    <div className="section-content">
                        <div className="container">
                            <div className="row">
                                <ServicesSidebar />
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">
                                        {/* TITLE START */}
                                        <div className="text-left">
                                            <h2 className="wt-title">Production Welding</h2>
                                        </div>
                                        {/* TITLE END */}
                                        <div className="wt-box services-etc m-b30">
                                            <p>Industries as diverse as automobile production and shipbuilding rely heavily on production welding. Production welders use high temperatures or other techniques to fuse metal components permanently. You can get your job done indoors, outdoors, or even beneath the water.</p>
                                            <div className="wt-media m-b30">
                                                <img src={require('./../../images/services/large/pic3.jpg').default} alt=""/>
                                            </div>
                                            <div className="wt-info">
                                                <p>Smoke from welders is a major contributor to air pollution at production facilities. In other cases, such as when welding galvanized or stainless steel, the byproducts may be very dangerous and must be contained. However, it's not always easy to collect smoke and fumes from processes like welding and cutting. This is not the case if you invest in a good welding fume extractor.</p>
                                                <blockquote className="m-a0 bg-gray p-a30">
                                                    <i className="fa fa-quote-left font-20" />
                                                    <p>Welding practises emit highly toxic smoke, dust, and fumes in operators’ breathing zones.
                    </p>

                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="service-provide">
                                            <h2>System Benefits</h2>
                                            <p>To protect workers, source capture ventilation devices such as our portable fume extractors draw fumes in and away from the operators' breathing zones. Indoor air is not contaminated.</p>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-angle-right">
                                                        <li>Reduce Absenteeism and Turnover</li>
                                                        <li>Increase Productivity and Accuracy</li>
                                                        <li>Prevent Contaminant Buildup on Surrounding Equipment</li>
                                                        <li>Meet Government Regulations</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="wt-media m-b30">
                                                        <img src={require('./../../images/gallery/pic3.jpg').default} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div>
                                            <h2>Research</h2>
                                            <div className="wt-accordion acc-bg-gray" id="accordion5">
                                                <div className="panel wt-panel">
                                                    <div className="acod-head acc-actives">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                                What problems we face?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseOne5" className="acod-body collapse show bg-gray">
                                                        <div className="acod-content p-a15">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, injected humour, or randomised words which don't look even slightly believablThere are many variations of passages.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                                Was our solution best?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseTwo5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                                Was our client satisfy?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseThree5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Developement lorem Ipsum is simply dummy text of the printing and type has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default SectorDetails_1;