import React from 'react';
import { NavLink } from 'react-router-dom';

class About7 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-no-repeat bg-center bg-white">
                    <div className="about-section-three">
                        <div className="container">
                            <div className="section-content">
                                <div className="row justify-content-center d-flex">
                                    <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="about-max-three">
                                            <div className="about-max-three-media"><img src={require('./../../images/ui-10/intro-pic.jpg').default} alt=""/></div>
                                            <div className="about-three">
                                                <div className="about-year">
                                                    <div className="about-year-info">
                                                        <span className="icon-md p-t10">
                                                            <i className="flaticon-industry" />
                                                        </span>
                                                        <h3 className="wt-tilte">Since 1980</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12 m-b30">
                                        <div className="about-section-three-right">
                                            {/* TITLE START*/}
                                            <div className="left wt-small-separator-outer">
                                                <div className="wt-small-separator site-text-primary">
                                                    <div className="sep-leaf-left" />
                                                    <div>About Enviroflex</div>
                                                    <div className="sep-leaf-right" />
                                                </div>
                                                <h2>Top quality air filtration systems for welding and laboratory applications.</h2>
                                            </div>
                                            {/* TITLE END*/}
                                            {/* Accordian */}
                                            <div className="wt-accordion acc-bg-gray m-b50 about-section-three-acc" id="accordion">
                                                <div className="panel wt-panel">
                                                    <div className="acod-head acc-actives" id="headingOne">
                                                        <h5 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                Top Quality
                          <span className="indicator"><i className="fa" /></span>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                        <div className="acod-content p-tb15">
                                                        Enviroflex manufactures top quality air filtration systems for welding and laboratory applications.
                      </div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head" id="headingTwo">
                                                        <h5 className="mb-0">
                                                            <a className="collapsed" data-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            Source Capture Principle
                          <span className="indicator"><i className="fa" /></span>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                                        <div className="acod-content p-tb15">
                                                        Our fume extraction equipment operates under the source capture principle. This maximizes the protection to workers by removing welding smoke, welding fumes and laboratory fumes before they get into the operators breathing area.
                      </div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head" id="headingThree">
                                                        <h5 className="mb-0">
                                                            <a className="collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            Reliable And Durable
                          <span className="indicator"><i className="fa" /></span>
                                                            </a>
                                                        </h5>
                                                    </div>
                                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                                        <div className="acod-content p-tb15">
                                                        Our smoke and fume extraction systems are reliable, durable and competitively priced. Enviroflex is known for excellence in customer service and technical support.
                      </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ab-three-info d-flex justify-content-between">
                                                <div className="welcom-btn-position m-t20"><NavLink to={"/difference"} className="site-button site-btn-effect">Our Difference</NavLink></div>
                                                                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About7;