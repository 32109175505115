import React from 'react';
import Header10 from './../Parts/Header10';
import Footer3 from './../Parts/Footer3';
import Slider10 from './../Segments/Slider10';
import CallUs1 from './../Segments/CallUs1';
import About7 from './../Segments/About7';
import Services10 from './../Segments/Services10';
import Blog5 from './../Segments/Blog5';


class Home10 extends React.Component {
    render() {
        return (
            <>
                <Header10 />
                    <div className="page-content">
                        <Slider10 />
                        <CallUs1 />
                        <About7 />
                        <Services10 />
                        <Blog5 />
                    </div>
                <Footer3 />
            </>
        );
    };
};

export default Home10;