import React from 'react';
import ServicesSidebar from './ServicesSidebar';

class SectorDetails_4 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full small-device  p-t80 p-b50 bg-white">
                    <div className="section-content">
                        <div className="container">
                            <div className="row">
                                <ServicesSidebar />
                                <div className="col-lg-8 col-md-12">
                                    <div className="section-content">
                                        {/* TITLE START */}
                                        <div className="text-left">
                                            <h2 className="wt-title">Plasma Cutting</h2>
                                        </div>
                                        {/* TITLE END */}
                                        <div className="wt-box services-etc m-b30">
                                            <p>Melting is accomplished in plasma cutting by directing a jet of ionized gas at temperatures exceeding 20,000°C toward the material to be cut, melting it and forcing the molten fragments out of the cut. To perform the procedure, an electrode (cathode) is brought into contact with the workpiece, resulting in an electric arc (anode). The arc is constrained in a water- or air-cooled gas nozzle, forming a narrow, high-velocity, and high-temperature plasma jet.</p>
                                            <div className="wt-media m-b30">
                                                <img src={require('./../../images/services/large/pic8.jpg').default} alt=""/>
                                            </div>
                                            <div className="wt-info">
                                                <p>The temperature at which the metal is being cut is far greater than the point at which it would melt. Because of this, the plasma jet rips through the metal with incredible speed. Consequently, the metal transforms into a very slender smoke or fume. Extraction of fumes produced by plasma cutters is very necessary to shield personnel from the hazards presented by these emissions.</p>
                                                <blockquote className="m-a0 bg-gray p-a30">
                                                    <i className="fa fa-quote-left font-20" />
                                                    <p>extraction of fumes produced by plasma cutters is very necessary
                    </p>
                                                    <div className="p-t15 text-uppercase">
                                                        <strong></strong>
                                                        <span></span>
                                                    </div>
                                                </blockquote>
                                            </div>
                                        </div>
                                        <div className="service-provide">
                                            <h2>System Benefits</h2>
                                            <p>To protect workers, source capture ventilation devices such as our portable fume extractors draw fumes in and away from the operators' breathing zones. Indoor air is not contaminated.</p>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="list-angle-right">
                                                        <li>Reduce Absenteeism and Turnover</li>
                                                        <li>Increase Productivity and Accuracy</li>
                                                        <li>Prevent Contaminant Buildup on Surrounding Equipment</li>
                                                        <li>Meet Government Regulations</li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="wt-media m-b30">
                                                        <img src={require('./../../images/gallery/pic3.jpg').default} alt=""/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div>
                                            <h2>Research</h2>
                                            <div className="wt-accordion acc-bg-gray" id="accordion5">
                                                <div className="panel wt-panel">
                                                    <div className="acod-head acc-actives">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                                What problems we face?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseOne5" className="acod-body collapse show bg-gray">
                                                        <div className="acod-content p-a15">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, injected humour, or randomised words which don't look even slightly believablThere are many variations of passages.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                                Was our solution best?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseTwo5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Graphic design lorem Ipsum is simply dummy text of the printing and type setting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took.</div>
                                                    </div>
                                                </div>
                                                <div className="panel wt-panel">
                                                    <div className="acod-head">
                                                        <h4 className="acod-title">
                                                            <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                                Was our client satisfy?<span className="indicator"><i className="fa fa-plus" /></span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div id="collapseThree5" className="acod-body collapse bg-gray">
                                                        <div className="acod-content p-a15">Developement lorem Ipsum is simply dummy text of the printing and type has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default SectorDetails_4;