import React from 'react';
import { NavLink } from 'react-router-dom';

const products = [
    {
        image: require('./../../images/products/pic-4.jpg'),
        title: 'Industrial Electronic Air Cleaner',
        link: '/ShopDetailIEAC',
        price: 'Request Quote'
    }    
]

class ShopProducts extends React.Component {
    render() {
        return (
            <>
                <div className="row">
                    {products.map((item, index) => (
                        <div key={index} className="col-lg-6 col-md-6 m-b30">
                            <div className="wt-box wt-product-box block-shadow  overflow-hide">
                                <div className="wt-thum-bx wt-img-overlay1 wt-img-effect zoom">
                                    <img src={item.image.default} alt="" />
                                    {/*<div className="overlay-bx">
                                        <div className="overlay-icon">
                                        <NavLink to={"#"}>
                                                <i className="fa fa-cart-plus wt-icon-box-xs" />
                                            </NavLink>
                                            <NavLink to={"#"} className="mfp-link">
                                                <i className="fa fa-heart wt-icon-box-xs" />
                                            </NavLink>
                                        </div>
                                    </div>*/}
                                </div>
                                <div className="wt-info  text-center">
                                    <div className="p-a20 bg-white">
                                        <h3 className="wt-title">
                                            <NavLink to={item.link}>{item.title}</NavLink>
                                        </h3>
                                        <span className="price">
                                            <ins>
                                                <span><span className="Price-currencySymbol"> </span>{item.price}</span>
                                            </ins>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        );
    }
};

export default ShopProducts;