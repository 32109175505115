import React from 'react';
import { NavLink } from 'react-router-dom';

class About extends React.Component {
    render() {
        return (
            <>
                <div className="section-full welcome-section-outer">
                    <div className="welcome-section-top bg-white p-tb80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="img-colarge">
                                        <div className="colarge-1"><img src={require('./../../images/colarge/s1.jpg').default} alt="" className="slide-righ" /></div>
                                        <div className="since-year-outer"><div className="since-year"><span>Since</span><strong>1980</strong></div></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="welcom-to-section">
                                        {/* TITLE START*/}
                                        <div className="left wt-small-separator-outer">
                                            <div className="wt-small-separator site-text-primary">
                                                <div className="sep-leaf-left" />
                                                <div>Welcome to Enviroflex</div>
                                                <div className="sep-leaf-right" />
                                            </div>
                                        </div>
                                        <h2>For A Healthier Work Environment</h2>
                                        {/* TITLE END*/}
                                        <ul className="site-list-style-one">
                                            <li>High durability and top performance products</li>
                                            <li>Replacement parts for all Enviromac products</li>
                                            <li>Environmental Sensitivity, Custom Solutions</li>
                                        </ul>
                                        <p>Our smoke and fume extraction systems are reliable, durable and competitively priced. Enviroflex is known for excellence in customer service and technical support.</p>
                                        <div className="welcom-to-section-bottom d-flex justify-content-between">
                                            <div className="welcom-btn-position"><NavLink to={"/shopfumeextractors"} className="site-button-secondry site-btn-effect">More About</NavLink></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default About;