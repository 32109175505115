import React from 'react';
import { NavLink } from 'react-router-dom';


class Footer3 extends React.Component {
    constructor(props) {
        super(props);
        var homepage9 = /\/home-industries9/i;
        var homepage10 = /\/home/i;
        var homepage12 = /\/home-conditioning-repair12/i;

        if (homepage9.test(window.location.href) || homepage10.test(window.location.href)) {
            this.currentstate = { logo: require('./../../images/logo-2-light.png').default };
        } else  if(homepage12.test(window.location.href)){
            this.currentstate = { logo: require('./../../images/logo-2-light.png').default };
        }else{
            this.currentstate = { logo: require('./../../images/logo-2-light.png').default };
        }
    }

    updateFooterLogo = (updatedlogo) => { this.currentstate.logo = updatedlogo.default; }

    render() {

        return (
            <>
                <footer className="site-footer footer-large footer-dark text-white footer-style3">
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="widget widget_about">
                                        <div className="logo-footer clearfix">
                                        <NavLink to={"./"}><img src={this.currentstate.logo} alt="" /></NavLink>
                                        </div>
                                        <p>Enviroflex has over 43 years of providing innovative technology to the welding and chemical industries. </p>
                                    </div>
                                    <div className="widget recent-posts-entry">
                                        <ul className="widget_address">
                                            <li><i className="fa fa-map-marker" />17 Brownridge Rd. Unit 8<br></br> Halton Hills, ON Canada L7G 0C6</li>
                                            <li> <i className="fa fa-phone" />(905) 636-1177 </li>
                                        </ul>
                                    </div>
                                    <div className="widget recent-posts-entry">
                                        <ul className="widget_address">
                                            <li><i className="fa fa-map-marker" />1051 Clinton Street, Unit 217<br></br> Buffalo, New York 14206-2823</li>
                                            <li> <i className="fa fa-phone" />(716) 883-2319 </li>
                                            <li><i className="fa fa-envelope" />info@enviroflex.com</li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="widget widget_services">
                                        <h3 className="widget-title">Products</h3>
                                        <ul>                                            
                                            <li><NavLink to={"/shopfumeextractors"}>Fume Extractors</NavLink></li>
                                            <li><NavLink to={"/ShopDetailMAC"}>ENVIROMAC™ Portable Smoke/Fume Extractor</NavLink></li>
                                            <li><NavLink to={"/shopAccessories"}>Accessories</NavLink></li>
                                            <li><NavLink to={"/shopFilters"}>Filters</NavLink></li>
                                            <li><NavLink to={"/shopParts"}>Replacement Parts</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="widget widget_services">
                                        <h3 className="widget-title">Industries We Serve</h3>
                                        <ul>
                                            <li><NavLink to={"/Sector_Production_Welding"}>Production Welding</NavLink></li>
                                            <li><NavLink to={"/Sector_Bench_Welding"}>Bench Welding</NavLink></li>
                                            <li><NavLink to={"/Sector_Arc_Air_Gouging"}>Arc Air Gouging</NavLink></li>
                                            <li><NavLink to={"/Sector_Plasma_Cutting"}>Plasma Cutting</NavLink></li>
                                            <li><NavLink to={"/Sector_Chemical_Industry"}>Chemical Industry</NavLink></li>
                                            <li><NavLink to={"/Sector_Laboratory"}>Laboratory</NavLink></li>                                            
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom">
                        <div className="container">
                            <div className="wt-footer-bot-left d-flex justify-content-between">
                                <span className="copyrights-text">Copyright © 2023 <span className="site-text-primary">TrueMobile</span></span>
                                <ul className="copyrights-nav">
                                <li><NavLink to={"/Terms"}>Terms  &amp; Conditions</NavLink></li>
                                    <li><NavLink to={"/Privacy"}>&nbsp;&nbsp;Privacy Policy</NavLink></li>
                                    <li><NavLink to={"/contactus"}>&nbsp;&nbsp;Contact Us</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>

                

            </>
        );
    };
};

export default Footer3;