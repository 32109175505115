import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Parts/Header10';
import Footer from './../Parts/Footer3';
import Banner from './../Segments/Banner';
import FeaturedProducts from './../Segments/FeaturedProducts';
import ImageGallery from 'react-image-gallery';
import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

const images = [
    {
        original: require('./../../images/products/pic-4.jpg').default,
        thumbnail: require('./../../images/products/thumb/pic4.jpg').default,
    },
    {
        original: require('./../../images/products/pic-4.jpg').default,
        thumbnail: require('./../../images/products/thumb/pic2.jpg').default,
    }
];

var bnrimg = require('./../../images/banner/5.jpg');

class ShopDetailIEAC extends React.Component {
    renderRightNav = (onClick, disabled) => {
        return (
            <button
              className='image-gallery-icon image-gallery-right-nav owl-next'
              onClick={onClick}
             ><i class="fa fa-chevron-right"></i></button>
          )
    };

    renderLeftNav = (onClick, disabled) => {
        return (
            <button
              className='image-gallery-icon image-gallery-left-nav owl-prev'
              onClick={onClick}
             ><i class="fa fa-chevron-left"></i></button>
          )
    };
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="Product Detail" pagename="MK2400 Industrial Electronic Air Cleaner" bgimage={bnrimg.default}/>
                    {/* INNER PAGE BANNER END */}
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-t80 p-b80">
                        {/* PRODUCT DETAILS */}
                        <div className="container woo-entry">
                            <div className="row m-b30">
                                <div className="col-lg-3 col-md-4  m-b30">
                                    <div className="wt-box wt-product-gallery on-show-slider">
                                    <ImageGallery renderLeftNav={this.renderLeftNav} renderRightNav={this.renderRightNav} items={images} showPlayButton={false} />
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-8">
                                    <div className="product-detail-info bg-gray p-a30">
                                        <div className="wt-product-title ">
                                            <h2 className="post-title"><NavLink to={"#"}>MK2400 Industrial Electronic Air Cleaner</NavLink></h2>
                                        </div>
                                        {/*<h3 className="m-tb10">$99.00 </h3>*/}
                                        {/*<div className="m-b15">
                                            <span className="rating-bx">
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star-o" />
                                            </span>
                                            <span className="font-weight-600 text-black">(4 customer reviews)</span>
                                        </div>*/}
                                        <div className="wt-product-text">
                                            <p>The MK2400 Air Cleaners are Industrial Electronic Air Cleaners that can be installed for general ventilation or ducted for source capture. They are highly effective for the removal of smoke, dust, fumes and oil mist down to a size of .01 micron. The units incorporate a lifelong filtration system that only needs to be washed. There are no throwaway filters.</p>
                                        </div>
                                        <form method="post" className="cart clearfix ">
                                            <button type="button" className="site-button-secondry m-r10 site-btn-effect m-b20"><i className="fa fa-shopping-bag" /> Request Quote</button>

                                        </form>
                                        <div className="product_meta">
                                            <span className="sku_wrapper">Model:
                <span className="sku"> MK2400</span>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* TABS CONTENT START */}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="wt-tabs border bg-tabs">
                                        <ul className="nav nav-tabs">
                                            <li><a data-toggle="tab" href="#web-design-19" className="active">Description</a></li>
                                            <li><a data-toggle="tab" href="#graphic-design-19">Specification</a></li>
                                            {/*<li><a data-toggle="tab" href="#developement-19">Review</a></li>*/}
                                        </ul>
                                        <div className="tab-content">
                                            <div id="web-design-19" className="tab-pane active">
                                                <div className=" p-a10">
                                                    <p className="m-b10">The MK2400 Air Cleaners are engineered and manufactured under the strictest of quality control standards offering customers these benefits:</p>
                                                    <ul className="site-list-style-two m-b0">
                                                        <li>Lower Energy Costs by recycling heated or cooled air as opposed to exhausting.</li>
                                                        <li>Reduce Absenteeism and Turnover by providing a healthier, safer work environment for all hands-on personnel. Office personnel will also be protected from drifting, irritating pollutants.</li>
                                                        <li>Increase Productivity and Accuracy due to a much more comfortable breathing environment.</li>
                                                        <li>Prevent Contaminant Buildup on Surrounding Equipment and reduce maintenance time.</li>
                                                        <li>Meet Government Regulations for indoor air quality and avoid costly penalties.</li>
                                                    </ul>
                                                </div>
                                                <p className="m-b10">Typical applications for the MK2400 are:</p>
                                                <h4>Brazing, Cold Forming, Dust, Wire Drawing, Heat Treating, Milling, Gear Cutting, Machining, Oil Mist, Plasma Cutting, Plastic Forming, Quenching, Welding Smoke, Wet Grinding, Soldering, Rubber Grinding, Rubber Forming, and Induction Heating.

</h4>
                                            </div>
                                            <div id="graphic-design-19" className="tab-pane">
                                                <table className="table m-b0 no-border">
                                                    <tbody>
                                                    <tr>
                                                        <th><b>Power Supply</b></th>
                                                        <td>The power supply i s adjustable. It is preset for 5,000 volts DC for the collector cells and 10,000 volts DC to the ionizer.</td>
                                                    </tr>
                                                        <tr>
                                                            <th><b>Switches</b></th>
                                                            <td>One switch turns the power supply on and off. The other switch turns the fan on and off.</td>                                                           
                                                        </tr>
                                                        <tr>
                                                            <th><b>Indicator Light</b></th>
                                                            <td>This indicates we have high voltage from the power supply. The light should be on when operational.</td>
                                                        </tr>
                                                        <tr>
                                                            <th><b>Ionizer</b></th>
                                                            <td>MK2400 has (2) Ionizers Dimensions: W=16" X L=19.75" X D=2.625" (W=60.64 CM X L=50 CM X D=6.66 CM) Weight: 7.8 Ibs (3.5kg) Tungsten Wires: 10 Rust-resistant satin coat steel. The lonizer creates a high-density electrical field to charge pollutants positive before entering the collector cell.</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Collector Cell</b></td>
                                                            <td>MK2400 has (2) collector cells. Cell dimensions: W=16"(40.64 CM) X L=20n(50.8 CM) X D=14.25"(36.19 CM) Weight: 25 Ibs. (1 1.3 kg). Plates: W=10.25"(26 CM) X L=18"(45.7 CM) X T=. 025"(.0635 CM). Collecting Surface Area: 120.3 square ft.(11.18 square meters). Maximum Velocity Through Cell: 500 FPM (152.4 m/min). Washable, corrosion-resistant aluminum collector cell never needs replacing. Captures welding fumes, oil mist, dust and other pollutants down to .O1 micron in size.</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Prefilter and After Filter</b></td>
                                                            <td>MK2400 has (2) prefilters and (2) after filters. Dimensions: W=16"(40.6 CM) X L=20"(50.8 CM) X D=l"(2.5 CM). Washable, multi-layered aluminum mesh collects agglomerated dust. The after filter creates back pressure to spread the incoming air evenly over the cells. This increases the filter efficiency.</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Activated Carbon Filter (Optional)</b></td>
                                                            <td>MK2400 has (2) carbon filters. Dimensions: W=16"(40.6 CM) X L=20"(50.8 CM) X D=l"(2.5 CM). Weight: 8 lbs (3.9kgs). The carbon filter removes most noxious odors and gases.</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Cabinet</b></td>
                                                            <td>18-gauge steel</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Mounting</b></td>
                                                            <td>Includes (4) 3/8" in U.N.C. captive weld nuts.</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Overall Dimensions</b></td>
                                                            <td>MK2400 W=34"(86.4 CM) X L=46"(116.8 CM) X H=27"(68.6 CM)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Weight of Machine</b></td>
                                                            <td>275 lbs (124.7kg)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Shipping Weight</b></td>
                                                            <td>325 lbs (127.4kg)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Cleaning Solution</b></td>
                                                            <td>Precipiclean™ is available in 5 gallon (22.7L) containers. The Precipiclean™ is a heavy duty cleaner expressly for the removal of accumulated pollutants on the collector cell plates.</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Options Available</b></td>
                                                            <td>Flex Adapter or Hi-Velocity Intake Hood</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Certifications</b></td>
                                                            <td>CSA and UL approved.</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div id="developement-19" className="tab-pane">
                                                <div className=" p-a20 bg-gray">
                                                    <div id="comments">
                                                        <ol className="commentlist">
                                                            <li className="comment">
                                                                <div className="comment_container">
                                                                    <img className="avatar avatar-60 photo" src={require('./../../images/testimonials/pic1.jpg').default} alt=""/>
                                                                    <div className="comment-text">
                                                                        <div className="star-rating">
                                                                            <div data-rating={3}>
                                                                                <i className="fa fa-star" data-alt={1} title="regular" />
                                                                                <i className="fa fa-star" data-alt={2} title="regular" />
                                                                                <i className="fa fa-star" data-alt={3} title="regular" />
                                                                                <i className="fa fa-star" data-alt={4} title="regular" />
                                                                                <i className="fa fa-star-o" data-alt={5} title="regular" />
                                                                            </div>
                                                                        </div>
                                                                        <p className="meta">
                                                                            <strong className="author">Cobus Bester</strong>
                                                                            <span><i className="fa fa-clock-o" /> March 20, 2020</span>
                                                                        </p>
                                                                        <div className="description">
                                                                            <p>Really happy with this print. The colors are great, and the paper quality is very good.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="comment">
                                                                <div className="comment_container">
                                                                    <img className="avatar avatar-60 photo" src={require('./../../images/testimonials/pic2.jpg').default} alt=""/>
                                                                    <div className="comment-text">
                                                                        <div className="star-rating">
                                                                            <div data-rating={3}>
                                                                                <i className="fa fa-star" data-alt={1} title="regular" />
                                                                                <i className="fa fa-star" data-alt={2} title="regular" />
                                                                                <i className="fa fa-star" data-alt={3} title="regular" />
                                                                                <i className="fa fa-star" data-alt={4} title="regular" />
                                                                                <i className="fa fa-star" data-alt={5} title="regular" />
                                                                            </div>
                                                                        </div>
                                                                        <p className="meta">
                                                                            <strong className="author">Cobus Bester</strong>
                                                                            <span><i className="fa fa-clock-o" /> March 12, 2020</span>
                                                                        </p>
                                                                        <div className="description">
                                                                            <p>Really happy with this print. The colors are great, and the paper quality is very good.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="comment">
                                                                <div className="comment_container">
                                                                    <img className="avatar avatar-60 photo" src={require('./../../images/testimonials/pic3.jpg').default} alt=""/>
                                                                    <div className="comment-text">
                                                                        <div className="star-rating">
                                                                            <div data-rating={3}>
                                                                                <i className="fa fa-star" data-alt={1} title="regular" />
                                                                                <i className="fa fa-star" data-alt={2} title="regular" />
                                                                                <i className="fa fa-star" data-alt={3} title="regular" />
                                                                                <i className="fa fa-star" data-alt={4} title="regular" />
                                                                                <i className="fa fa-star-o" data-alt={5} title="regular" />
                                                                            </div>
                                                                        </div>
                                                                        <p className="meta">
                                                                            <strong className="author">Cobus Bester</strong>
                                                                            <span><i className="fa fa-clock-o" /> March 11, 2020</span>
                                                                        </p>
                                                                        <div className="description">
                                                                            <p>Really happy with this print. The colors are great, and the paper quality is very good.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ol>
                                                    </div>
                                                    <div id="review_form_wrapper">
                                                        <div id="review_form">
                                                            <div id="respond" className="comment-respond">
                                                                <h3 className="comment-reply-title" id="reply-title">Add a review</h3>
                                                                <form className="comment-form" method="post" action="#">
                                                                    <div className="comment-form-author">
                                                                        <label>Name <span className="required">*</span></label>
                                                                        <input type="text" aria-required="true" size={30} defaultValue name="author" id="author" />
                                                                    </div>
                                                                    <div className="comment-form-email">
                                                                        <label>Email <span className="required">*</span></label>
                                                                        <input type="text" aria-required="true" size={30} defaultValue name="email" id="email" />
                                                                    </div>
                                                                    <div className="comment-form-rating">
                                                                        <label>Your Rating</label>
                                                                        <div className="star-Rating-input">
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star-o" />
                                                                            <i className="fa fa-star-o" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="comment-form-comment">
                                                                        <label>Your Review</label>
                                                                        <textarea aria-required="true" rows={8} cols={45} name="comment" id="comment" defaultValue={""} />
                                                                    </div>
                                                                    <div className="form-submit">
                                                                        <button type="button" className="site-button site-btn-effect">Submit</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* TABS CONTENT START */}
                        </div>
                        {/* PRODUCT DETAILS */}
                    </div>
                    {/* CONTENT CONTENT END */}
                    <FeaturedProducts />
                </div>
                <Footer />
            </>
        );
    };
};

export default ShopDetailIEAC;