import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: '01',
        image: require('./../../images/ui-10/services/1.jpg'),
        flaticon: 'flaticon-conveyor',
        title: 'Production Welding',
        link: '/Sector_Production_Welding',
        description: 'Commonly used in manufacturing and construction industries.'
    },
    {
        count: '02',
        image: require('./../../images/ui-10/services/2.jpg'),
        flaticon: 'flaticon-stamp',
        title: 'Bench Welding',
        link: '/Sector_Bench_Welding',
        description: 'Shield your lungs from harmful vapors and fumes while working on the bench.'
    },
    {
        count: '03',
        image: require('./../../images/ui-10/services/3.jpg'),
        flaticon: 'flaticon-stamp',
        title: 'Arc Air Gouging',
        link: '/Sector_Arc_Air_Gouging',
        description: 'An air-arc gouging operation often produces more toxic fumes.'
    },
    {
        count: '04',
        image: require('./../../images/ui-10/services/4.jpg'),
        flaticon: 'flaticon-robotic-arm',
        title: 'Plasma Cutting',
        link: '/Sector_Plasma_Cutting',
        description: 'Extraction of fumes produced by plasma cutters is very necessary.'
    },
    {
        count: '05',
        image: require('./../../images/ui-10/services/5.jpg'),
        flaticon: 'flaticon-industry',
        title: 'Chemical Industry',
        link: '/Sector_Chemical_Industry',
        description: 'Chemicals directly threaten individuals via touch or inhalation as dust or fume.'
    },
    {
        count: '06',
        image: require('./../../images/ui-10/services/6.jpg'),
        flaticon: 'flaticon-healthcare-and-medical',
        title: 'Laboratory',
        link: '/Sector_Laboratory',
        description: 'Extraction systems may be required in a lab where hazardous chemicals are used.'
    }
]

class Services10 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white">
                    <div className="container">
                        {/* TITLE START*/}
                        <div className="section-head center wt-small-separator-outer text-center">
                            <div className="wt-small-separator site-text-primary">
                                <div className="sep-leaf-left" />
                                <div>The Best Fume Extractors</div>
                                <div className="sep-leaf-right" />
                            </div>
                            <h2>High Performance Fume Extractors For Multiple Industries!</h2>
                        </div>
                        {/* TITLE END*/}
                        <div className="section-content">
                            <div className="row justify-content-center">
                                {services.map((item, index) => (
                                    <div key={index} className="col-lg-4 col-md-6 m-b30 animate_line">
                                        <div className="wt-box service-box-1-new bg-white cardbg">
                                            <div className="wt-icon-box-wraper-new">
                                                <div className="icon-xl inline-icon">
                                                    <span className="icon-cell site-text-primary"><i className={item.flaticon} /></span>
                                                </div>
                                                <div className="wt-icon-number-new"><span>{item.count}</span></div>
                                            </div>
                                            <div className="service-box-title-new title-style-2 site-text-secondry">
                                                <h3 className="s-title-one">{item.title}</h3>
                                            </div>
                                            <div className="service-box-content-new">
                                                <p>{item.description}</p>
                                            </div>
                                            <div className="service-box-new-link">
                                            <NavLink to={item.link} className="site-button-link">Read More</NavLink>
                                            </div>
                                            <div className="cardbg-pic" style={{ backgroundImage: "url(" + item.image.default + ")" }} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Services10;