import React from 'react';
import { NavLink } from 'react-router-dom';

class Navigation extends React.Component {

    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };

    render() {
        return (
            <>
                <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
                    <ul className=" nav navbar-nav">
                        <li><NavLink to={"home"}>Home</NavLink>
                        </li>
                        <li><NavLink to={"/about"}>About</NavLink>
                        </li>
                        <li><NavLink to={"/Sectors"}>Sectors</NavLink>
                        <ul className="sub-menu">
                                <li><NavLink to={"/Sector_Production_Welding"}>Production Welding</NavLink></li>
                                <li><NavLink to={"/Sector_Bench_Welding"}>Bench Welding</NavLink></li>
                                <li><NavLink to={"/Sector_Arc_Air_Gouging"}>Arc Air Gouging</NavLink></li>
                                <li><NavLink to={"/Sector_Plasma_Cutting"}>Plasma Cutting</NavLink></li>
                                <li><NavLink to={"/Sector_Chemical_Industry"}>Chemical Industry</NavLink></li>
                                <li><NavLink to={"/Sector_Laboratory"}>Laboratory</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink to={""}>Products</NavLink>
                            <ul className="sub-menu">
                                <li><NavLink to={"/shopfumeextractors"}>Fume Extractors</NavLink></li>
                                <li><NavLink to={"/shopAirCleaners"}>Air Cleaners</NavLink></li>
                                <li><NavLink to={"/shopFilters"}>Filters</NavLink></li>

                                <li><NavLink to={"/shopAccessories"}>Accessories</NavLink></li>
                                <li><NavLink to={"/shopParts"}>Parts</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink to={""}>Resources</NavLink>
                            <ul className="sub-menu">
                                <li><NavLink to={"/difference"}>Enviroflex Difference</NavLink></li>
                                
                                <li><NavLink to={"/FAQ"}>FAQ</NavLink></li>
                            </ul>
                        </li>
                        <li><NavLink to={"/contactus"}>Contact us</NavLink></li>
                    </ul>
                </div>
            </>
        );
    };
};

export default Navigation;