import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Parts/Header10';
import Footer from './../Parts/Footer3';
import Banner from './../Segments/Banner';
import PageSidebar from './../Segments/PageSidebar';

var bnrimg = require('./../../images/banner/5.jpg');

class Difference extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="Enviroflex Difference" pagename="Our Difference" bgimage={bnrimg.default} />
                    {/* INNER PAGE BANNER END */}
                    {/* OUR BLOG START */}
                    <div className="section-full  p-t80 p-b50 bg-white">
                        <div className="container">
                            {/* BLOG SECTION START */}
                            <div className="section-content">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 m-b30">
                                        {/* BLOG START */}
                                        <div className="blog-post date-style-2 blog-lg">
                                            <div className="wt-post-media wt-img-effect zoom-slow">
                                                <NavLink to={"#"}><img src={require('./../../images/blog/default/thum1.jpg').default} alt="" /></NavLink>
                                            </div>
                                            <div className="wt-post-info  bg-white p-t30">
                                                <div className="wt-post-meta ">
                                                    <ul>
                                                        <li className="post-category"><span>Extractors</span> </li>
                                                        <li className="post-date">February 28, 2022</li>
                                                    </ul>
                                                </div>
                                                <div className="wt-post-title ">
                                                    <h2 className="post-title">Enviroflex fume and smoke extraction products for welding, dust, laboratory and pharmaceutical applications are among the best in the world.</h2>
                                                </div>
                                                <div className="wt-post-text">
                                                    <p>Our commitment to excellence and customer satisfaction has made us the preferred choice in fume extraction for over 43 years.</p>
                                                    <p><b>Our Difference?</b></p>
                                                    <ul >
                                                     <li>Sturdy, quality constructed products</li>
                                                     <li>Exceptional equipment performance & reliability</li>
                                                     <li>Unmatched Customer service and support</li>
                                                     <li>Service and parts for competitors equipment</li>
                                                    </ul>
                                                    <p>Enviroflex was established in 1979 by welding equipment specialist, Glen Love. At the time, the demand for portable fume extraction devices was growing steadily as the need to create safer work environments became a high priority for several industries. Initially, Enviroflex sourced equipment components from a supplier in Sweden but soon decided to design and manufacture their fume extractors and air filtration systems in North America.</p>
                                                    <p>Using local talent, Enviroflex located a mechanical technologist capable of building the fume extractor equipment to exacting standards. Eventually a US office was opened to facilitate more efficient delivery and service of product to our US customers. Enviroflex moved their head office to new facilities on Brownridge Rd in Halton Hills, Ontario in 2008 and continue to maintain their US office in Buffalo, New York as well.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* BLOG END */}
                                    </div>
                                    {/* SIDE BAR START */}
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 rightSidebar  m-b30">
                                        <PageSidebar />
                                    </div>
                                    {/* SIDE BAR END */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* OUR BLOG END */}
                </div>
                <Footer />
            </>
        );
    };
};

export default Difference;