import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Parts/Header10';
import Footer from '../Parts/Footer3';
import Banner from '../Segments/Banner';


var bnrimg = require('./../../images/banner/5.jpg');

class Privacy extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="Privacy Policy" pagename="Privacy Policy" bgimage={bnrimg.default} />
                    {/* INNER PAGE BANNER END */}
                    {/* OUR BLOG START */}
                    <div className="section-full  p-t80 p-b50 bg-white">
                        <div className="container">
                            {/* BLOG SECTION START */}
                            <div className="section-content">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 m-b30">
                                        {/* BLOG START */}
                                        <div className="blog-post date-style-2 blog-lg">
                                            <div className="wt-post-info  bg-white p-t30">
                                                <div className="wt-post-meta ">
                                                </div>
                                                <div className="wt-post-title ">
                                                    <h2 className="post-title">Privacy Policy</h2>
                                                </div>
                                                <div className="wt-post-text">
                                                <h4 class="text-highlight">Introduction</h4>
                                                    <p>Your privacy is important to Enviroflex International Inc.. This privacy statement provides information about the personal information that Enviroflex International Inc. collects, and the ways in which Enviroflex International Inc. uses that personal information.</p>
                                                </div>
                                                <br></br>
                                                <div className="wt-post-text">
                                                <h4 class="text-highlight">Personal information collection</h4>
                                                    <p>Enviroflex International Inc. may collect and use the following kinds of personal information:</p>
                                                    <ul>
                                                        <li>information about your use of this website</li>
                                                        <li>information that you provide using for the purpose of registering with the website</li>
                                                        <li>information about transactions carried out over this website</li>
                                                        <li>information that you provide for the purpose of subscribing to the website services and</li>
                                                        <li>any other information that you send to Enviroflex International Inc..</li>
                                                    </ul>
                                                </div>
                                                <br></br>
                                                <div className="wt-post-text">
                                                <h4 class="text-highlight">Using personal information</h4>
                                                <p>Enviroflex International Inc. may use your personal information to:</p>
                                                <ul>
                                                    <li>administer this website;</li>
                                                    <li>personalize the website for you;</li>
                                                    <li>enable your access to and use of the website services;</li>
                                                    <li>publish information about you on the website;</li>
                                                    <li>send to you products that you purchase;</li>
                                                    <li>supply to you services that you purchase;</li>
                                                    <li>send to you statements and invoices;</li>
                                                    <li>collect payments from you; and</li>
                                                    <li>send you marketing communications.</li>
                                                </ul>
                                                <p>Where Enviroflex International Inc. discloses your personal information to its agents or sub-contractors for these purposes, the agent or sub-contractor in question will be obligated to use that personal information in accordance with the terms of this privacy statement.</p>
                                                <p>In addition to the disclosures reasonably necessary for the purposes identified elsewhere above, Enviroflex International Inc. may disclose your personal information to the extent that it is required to do so by law, in connection with any legal proceedings or prospective legal proceedings, and in order to establish, exercise or defend its legal rights.</p>
                                                </div>
                                                <br></br>
                                                <div className="wt-post-text">
                                                <h4 class="text-highlight">Securing your data</h4>   
                                                <p>Enviroflex International Inc. will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.</p>
                                                <p>Enviroflex International Inc. will store all the personal information you provide on its secure servers.</p>
                                                <p>Information relating to electronic transactions entered into via this website will be protected by encryption technology.</p>
                                                </div>
                                                <br></br>
                                                <div className="wt-post-text">
                                                <h4 class="text-highlight">Cross-border data transfers</h4>
                                                <p>Information that Enviroflex International Inc. collects may be stored and processed in and transferred between any of the countries in which Enviroflex International Inc. operates to enable the use of the information in accordance with this privacy policy.</p>
                                                <p>In addition, personal information that you submit for publication on the website will be published on the internet and may be available around the world.</p>
                                                <p>You agree to such cross-border transfers of personal information.</p>                                                
                                                </div>
                                                <br></br>
                                                <div className="wt-post-text">
                                                <h4 class="text-highlight">Updating this statement</h4>   
                                                <p>Enviroflex International Inc. may update this privacy policy by posting a new version on this website.</p>
                                                <p>You should check this page occasionally to ensure you are familiar with any changes.</p>
                                                </div>
                                                <br></br>
                                                <div className="wt-post-text">
                                                <h4 class="text-highlight">Other websites</h4>
                                                <p>This website may contains links to other websites.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* BLOG END */}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* OUR BLOG END */}
                </div>
                <Footer />
            </>
        );
    };
};

export default Privacy;