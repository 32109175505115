import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Parts/Header10';
import Footer from './../Parts/Footer3';
import Banner from './../Segments/Banner';
import FeaturedProducts from './../Segments/FeaturedProducts';
import ImageGallery from 'react-image-gallery';
import '../../../node_modules/react-image-gallery/styles/css/image-gallery.css';

const images = [
    {
        original: require('./../../images/products/pic-1.jpg').default,
        thumbnail: require('./../../images/products/thumb/pic1.jpg').default,
    },
    {
        original: require('./../../images/products/pic-1.jpg').default,
        thumbnail: require('./../../images/products/thumb/pic2.jpg').default,
    },
    {
        original: require('./../../images/products/pic-1.jpg').default,
        thumbnail: require('./../../images/products/thumb/pic3.jpg').default,
    },
    {
        original: require('./../../images/products/pic-1.jpg').default,
        thumbnail: require('./../../images/products/thumb/pic4.jpg').default,
    },
    {
        original: require('./../../images/products/pic-1.jpg').default,
        thumbnail: require('./../../images/products/thumb/pic5.jpg').default,
    }
];

var bnrimg = require('./../../images/banner/5.jpg');

class ShopDetailESESLV extends React.Component {
    renderRightNav = (onClick, disabled) => {
        return (
            <button
              className='image-gallery-icon image-gallery-right-nav owl-next'
              onClick={onClick}
             ><i class="fa fa-chevron-right"></i></button>
          )
    };

    renderLeftNav = (onClick, disabled) => {
        return (
            <button
              className='image-gallery-icon image-gallery-left-nav owl-prev'
              onClick={onClick}
             ><i class="fa fa-chevron-left"></i></button>
          )
    };
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="Product Detail" pagename="ESE HandHeld Welding Smoke Extractors SLV" bgimage={bnrimg.default}/>
                    {/* INNER PAGE BANNER END */}
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-t80 p-b80">
                        {/* PRODUCT DETAILS */}
                        <div className="container woo-entry">
                            <div className="row m-b30">
                                <div className="col-lg-3 col-md-4  m-b30">
                                    <div className="wt-box wt-product-gallery on-show-slider">
                                    <ImageGallery renderLeftNav={this.renderLeftNav} renderRightNav={this.renderRightNav} items={images} showPlayButton={false} />
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-8">
                                    <div className="product-detail-info bg-gray p-a30">
                                        <div className="wt-product-title ">
                                            <h2 className="post-title"><NavLink to={"#"}>ESE HandHeld Welding Smoke Extractors</NavLink></h2>
                                            <h3>Standard Low Vacuum Models</h3>
                                        </div>
                                        {/*<h3 className="m-tb10">$99.00 </h3>*/}
                                        {/*<div className="m-b15">
                                            <span className="rating-bx">
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star" />
                                                <i className="fa fa-star-o" />
                                            </span>
                                            <span className="font-weight-600 text-black">(4 customer reviews)</span>
                                        </div>*/}
                                        <div className="wt-product-text">
                                            <p>The Enviroflex Smoke Extractor (ESE) is a lightweight, suitcase sized air cleaner that is very easy to carry. The contaminant is trapped at the source, thus maximizing the protection to the operator by removing contaminant before it enters the operators breathing area. The unit recycles clean air back into the building eliminating additional heating or cooling costs.</p>
                                        </div>
                                        <form method="post" className="cart clearfix ">
                                            <button type="button" className="site-button-secondry m-r10 site-btn-effect m-b20"><i className="fa fa-shopping-bag" /> Request Quote</button>

                                        </form>
                                        <div className="product_meta">
                                            <span className="sku_wrapper">Models:
                <span className="sku"> ESE101A-W-2 / ESE201A-W-2 / ESE102A-2 / ESE202A-2</span>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* TABS CONTENT START */}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="wt-tabs border bg-tabs">
                                        <ul className="nav nav-tabs">
                                            <li><a data-toggle="tab" href="#web-design-19" className="active">Description</a></li>
                                            <li><a data-toggle="tab" href="#graphic-design-19">Specification</a></li>
                                            {/*<li><a data-toggle="tab" href="#developement-19">Review</a></li>*/}
                                        </ul>
                                        <div className="tab-content">
                                            <div id="web-design-19" className="tab-pane active">
                                                <div className=" p-a10">
                                                    <p className="m-b10">Ideal for the maintenance and repair welding industries this handheld fume/smoke extractor is equipped with the following features:</p>
                                                    <ul className="site-list-style-two m-b0">
                                                        <li>The unit has been designed to accommodate an optional charcoal filter to eliminate various gases after the particulate has been collected.</li>
                                                        <li>Standard 15 ft. hose comes with the unit with the option to purchase 25 or 50 foot lengths.</li>
                                                        <li>High efficiency washable filter and optional throwaway paper filter or HEPA filter for Hexavalent Chromium fumes when welding stainless steel.</li>
                                                        <li>Portable and easy to manoeuvre to accommodate use in hard to reach places.</li>
                                                        <li>Ideal for the collection of various hazardous airborne particulates such as asbestos, stainless steel, silica, carbon dust, fibreglass, galvanized steel, lead oxides etc.</li>
                                                        <li>Available with one or two motors (120 volt 50/60HZ ) – 4 standard vacuum models to choose from for nozzle applications.</li>
                                                        <li>Optional stainless steel cabinet suitable for use in corrosive environment e.g. salt mine or salt water atmosphere.</li>
                                                        <li>Multiple nozzle options include 3” or 8” wide with base, magnet and metal flex duct.</li>
                                                        <li>Optional ‘Y’ connector is used to run two stations from a single unit.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div id="graphic-design-19" className="tab-pane">
                                                <table className="table m-b0 no-border">
                                                    <tbody>
                                                    <tr>
                                                        <th><b>Model</b></th>
                                                        <th>ESE101A-W-2</th>
                                                        <th>ESE201A-W-2</th>
                                                        <th>ESE102A-2</th>
                                                        <th>ESE202A-2</th>
                                                    </tr>
                                                        <tr>
                                                            <th><b>Air Flow With Load Conditions (Hose and Nozzle Connected)</b></th>
                                                            <td>80 C.F.M. (135 m3/hr)</td>
                                                            <td>160 C.F.M. (270 m3/hr)</td>
                                                            <td>80 C.F.M. (135 m3/hr)</td>
                                                            <td>160 C.F.M. (270 m3/hr)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Air Flow With No Load Conditions (No Hose or Nozzle Connected)</b></td>
                                                            <td>113 C.F.M. (192 m3/hr)</td>
                                                            <td>225 C.F.M. (382 m3/hr)</td>
                                                            <td>113 C.F.M. (192 m3/hr)</td>
                                                            <td>225 C.F.M. (382 m3/hr)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Filtration Efficiency</b></td>
                                                            <td>0.3 - 1 um (46%), 1.0 -3.0 um (61%), 3.0 -10 um (95%)</td>
                                                            <td>0.3 - 1 um (46%), 1.0 -3.0 um (61%), 3.0 -10 um (95%)</td>
                                                            <td>(EF100-1.0-3.0 Microns (50%-64.9%),3.0-10.0 Microns (85% or greater)) (EF200-0.3 -1 Micron (99.97%))</td>
                                                            <td>(EF100-1.0-3.0 Microns (50%-64.9%),3.0-10.0 Microns (85% or greater)) (EF200-0.3 -1 Micron (99.97%))</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Number of Filters</b></td>
                                                            <td>1 (EF200A)***</td>
                                                            <td>1 (EF200A)***</td>
                                                            <td>2 (EF100+EF200)</td>
                                                            <td>2 (EF100+EF200)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Type of Filters</b></td>
                                                            <td>Washable</td>
                                                            <td>Washable</td>
                                                            <td>Throwaway</td>
                                                            <td>Throwaway</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Maximum Vacuum</b></td>
                                                            <td>80”W.G. (21 KPA)</td>
                                                            <td>80”W.G. (21 KPA)</td>
                                                            <td>80”W.G. (21 KPA)</td>
                                                            <td>80”W.G. (21 KPA)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Number of Motors</b></td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Type of Motors</b></td>
                                                            <td>Bypass</td>
                                                            <td>Bypass</td>
                                                            <td>Bypass</td>
                                                            <td>Bypass</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Voltage</b></td>
                                                            <td>120 Volts, 50/60 HZ</td>
                                                            <td>120 Volts, 50/60 HZ</td>
                                                            <td>120 Volts, 50/60 HZ</td>
                                                            <td>120 Volts, 50/60 HZ</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Amperage</b></td>
                                                            <td>6.5 amps</td>
                                                            <td>13 amps</td>
                                                            <td>6.5 amps</td>
                                                            <td>13 amps</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Dimensions</b></td>
                                                            <td>L 26 ½” X W 9 ¼” X H 14 ½”</td>
                                                            <td>L 26 ½” X W 9 ¼” X H 14 ½”</td>
                                                            <td>L 26 ½” X W 9 ¼” X H 14 ½”</td>
                                                            <td>L 26 ½” X W 9 ¼” X H 14 ½”</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Weight</b></td>
                                                            <td>37Lbs.</td>
                                                            <td>41Lbs.</td>
                                                            <td>39Lbs.</td>
                                                            <td>43Lbs.</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Hose Length</b></td>
                                                            <td>15ft. (4.6M)</td>
                                                            <td>15ft. (4.6M)</td>
                                                            <td>15ft. (4.6M)</td>
                                                            <td>15ft. (4.6M)</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Hose I.D.</b></td>
                                                            <td>1¾” (45mm)</td>
                                                            <td>1¾” (45mm)</td>
                                                            <td>1¾” (45mm)</td>
                                                            <td>1¾” (45mm)</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <br></br>
                                                <h4>***Please note Precoat should be used with the Washable Filter(EF200A) only.</h4>
                                            </div>
                                            <div id="developement-19" className="tab-pane">
                                                <div className=" p-a20 bg-gray">
                                                    <div id="comments">
                                                        <ol className="commentlist">
                                                            <li className="comment">
                                                                <div className="comment_container">
                                                                    <img className="avatar avatar-60 photo" src={require('./../../images/testimonials/pic1.jpg').default} alt=""/>
                                                                    <div className="comment-text">
                                                                        <div className="star-rating">
                                                                            <div data-rating={3}>
                                                                                <i className="fa fa-star" data-alt={1} title="regular" />
                                                                                <i className="fa fa-star" data-alt={2} title="regular" />
                                                                                <i className="fa fa-star" data-alt={3} title="regular" />
                                                                                <i className="fa fa-star" data-alt={4} title="regular" />
                                                                                <i className="fa fa-star-o" data-alt={5} title="regular" />
                                                                            </div>
                                                                        </div>
                                                                        <p className="meta">
                                                                            <strong className="author">Cobus Bester</strong>
                                                                            <span><i className="fa fa-clock-o" /> March 20, 2020</span>
                                                                        </p>
                                                                        <div className="description">
                                                                            <p>Really happy with this print. The colors are great, and the paper quality is very good.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="comment">
                                                                <div className="comment_container">
                                                                    <img className="avatar avatar-60 photo" src={require('./../../images/testimonials/pic2.jpg').default} alt=""/>
                                                                    <div className="comment-text">
                                                                        <div className="star-rating">
                                                                            <div data-rating={3}>
                                                                                <i className="fa fa-star" data-alt={1} title="regular" />
                                                                                <i className="fa fa-star" data-alt={2} title="regular" />
                                                                                <i className="fa fa-star" data-alt={3} title="regular" />
                                                                                <i className="fa fa-star" data-alt={4} title="regular" />
                                                                                <i className="fa fa-star" data-alt={5} title="regular" />
                                                                            </div>
                                                                        </div>
                                                                        <p className="meta">
                                                                            <strong className="author">Cobus Bester</strong>
                                                                            <span><i className="fa fa-clock-o" /> March 12, 2020</span>
                                                                        </p>
                                                                        <div className="description">
                                                                            <p>Really happy with this print. The colors are great, and the paper quality is very good.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="comment">
                                                                <div className="comment_container">
                                                                    <img className="avatar avatar-60 photo" src={require('./../../images/testimonials/pic3.jpg').default} alt=""/>
                                                                    <div className="comment-text">
                                                                        <div className="star-rating">
                                                                            <div data-rating={3}>
                                                                                <i className="fa fa-star" data-alt={1} title="regular" />
                                                                                <i className="fa fa-star" data-alt={2} title="regular" />
                                                                                <i className="fa fa-star" data-alt={3} title="regular" />
                                                                                <i className="fa fa-star" data-alt={4} title="regular" />
                                                                                <i className="fa fa-star-o" data-alt={5} title="regular" />
                                                                            </div>
                                                                        </div>
                                                                        <p className="meta">
                                                                            <strong className="author">Cobus Bester</strong>
                                                                            <span><i className="fa fa-clock-o" /> March 11, 2020</span>
                                                                        </p>
                                                                        <div className="description">
                                                                            <p>Really happy with this print. The colors are great, and the paper quality is very good.</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ol>
                                                    </div>
                                                    <div id="review_form_wrapper">
                                                        <div id="review_form">
                                                            <div id="respond" className="comment-respond">
                                                                <h3 className="comment-reply-title" id="reply-title">Add a review</h3>
                                                                <form className="comment-form" method="post" action="#">
                                                                    <div className="comment-form-author">
                                                                        <label>Name <span className="required">*</span></label>
                                                                        <input type="text" aria-required="true" size={30} defaultValue name="author" id="author" />
                                                                    </div>
                                                                    <div className="comment-form-email">
                                                                        <label>Email <span className="required">*</span></label>
                                                                        <input type="text" aria-required="true" size={30} defaultValue name="email" id="email" />
                                                                    </div>
                                                                    <div className="comment-form-rating">
                                                                        <label>Your Rating</label>
                                                                        <div className="star-Rating-input">
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star" />
                                                                            <i className="fa fa-star-o" />
                                                                            <i className="fa fa-star-o" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="comment-form-comment">
                                                                        <label>Your Review</label>
                                                                        <textarea aria-required="true" rows={8} cols={45} name="comment" id="comment" defaultValue={""} />
                                                                    </div>
                                                                    <div className="form-submit">
                                                                        <button type="button" className="site-button site-btn-effect">Submit</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* TABS CONTENT START */}
                        </div>
                        {/* PRODUCT DETAILS */}
                    </div>
                    {/* CONTENT CONTENT END */}
                    <FeaturedProducts />
                </div>
                <Footer />
            </>
        );
    };
};

export default ShopDetailESESLV;