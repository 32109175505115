import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Parts/Header10';
import Footer from './../Parts/Footer3';
import Banner from './../Segments/Banner';

var bnrimg = require('./../../images/banner/8.jpg');

class Home3 extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="FAQ'S" pagename="FAQ'S" bgimage={bnrimg.default} />
                    {/* INNER PAGE BANNER END */}
                    {/* FAQ SECTION START */}
                    <div className="section-full p-t80 p-b50 bg-white">
                        <div className="container">
                            {/* TITLE START*/}
                            <div className="section-head center wt-small-separator-outer text-center">
                                <div className="wt-small-separator site-text-primary">
                                    <div className="sep-leaf-left" />
                                    <div>Frequently Asked Questions</div>
                                    <div className="sep-leaf-right" />
                                </div>
                                <h2>Find informative answers to all your questions about our fume extractors below.</h2>
                            </div>
                            {/* TITLE END*/}
                            <div className="section-content">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-lg-8 col-md-6 col-sm-12">
                                        {/* Accordian */}
                                        <div className="wt-accordion acc-bg-gray faq-accorfion" id="accordion5">
                                            <div className="panel wt-panel">
                                                <div className="acod-head  acc-actives">
                                                    <h3 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseOne5" data-parent="#accordion5">
                                                        Why do I need a fume extractor? What are the hazards of welding smoke and fumes?
                      <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div id="collapseOne5" className="acod-body collapse show">
                                                    <div className="acod-content p-a20 bg-gray">
                                                        <p>
                                                        There are more than 80 different types of welding and they are hazardous for various reasons. The main reason is that particles and gases that create adverse side effects can be inhaled during the welding process. When heated rapidly, part of the metal is vaporized and becomes sub-micron particles in the air that you breathe. Many of these metals can be quite toxic including chromium, nickel, arsenic, asbestos, manganese, lead and zinc.</p>
                                                        </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head">
                                                    <h3 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseTwo5" className="collapsed" data-parent="#accordion5">
                                                        Which fume extractor is right for my application?
                      <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div id="collapseTwo5" className="acod-body collapse">
                                                    <div className="acod-content p-a20 bg-gray">
                                                    Depending on your application you will need to consider the number of work stations, size and type of filter, and type of filler metal to be used. You will also need to determine your filter requirements. For example, will there be one main filter for several small stations or will each station require its own source capture and individual filter? Will you be working in a fixed area or will you need the fume extractor to be portable? These and other factors come into play when determining which fume extractor is ideal for your situation. We often ask customers to take a picture of the shop layout and email it to us so that we can help with recommendations.
                  </div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head">
                                                    <h3 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseThree5" className="collapsed" data-parent="#accordion5">
                                                        Are fume extractors designed for indoor or outdoor use?
                      <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div id="collapseThree5" className="acod-body collapse">
                                                    <div className="acod-content p-a20 bg-gray">The majority of our customers use Enviroflex products for indoor applications where there is no natural ventilation and fumes or smoke need to be removed from the work area, filtered, and recycled back into the plant to maximize customer safety.</div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head">
                                                    <h3 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFour5" className="collapsed" data-parent="#accordion5">
                                                        How will I know when it’s time for a filter replacement?
                      <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div id="collapseFour5" className="acod-body collapse">
                                                    <div className="acod-content p-a20 bg-gray">This depends on the filter media you are using.</div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head">
                                                    <h3 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive5" className="collapsed" data-parent="#accordion5">
                                                        What is a HEPA filter?
                      <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div id="collapseFive5" className="acod-body collapse">
                                                    <div className="acod-content p-a20 bg-gray">HEPA filters are rated at capturing 99.97% of weld fume particles at 0.3 microns. HEPA filters are often used with respirators and are an optional addition to the ESE handheld and the Enviromac system. HEPA filters are known for their effectiveness in removing the hazardous hexavalent chromium fumes generated when welding stainless steel.</div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head">
                                                    <h3 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive6" className="collapsed" data-parent="#accordion5">
                                                        Can the filters I purchase from Enviroflex be cleaned and reused?
                      <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div id="collapseFive6" className="acod-body collapse">
                                                    <div className="acod-content p-a20 bg-gray">We offer both reusable filters as well as throwaway filters, depending on your need and application.</div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head">
                                                    <h3 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive7" className="collapsed" data-parent="#accordion5">
                                                        What makes one filter type preferred over another? How do I choose which is right for my application?
                      <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div id="collapseFive7" className="acod-body collapse">
                                                    <div className="acod-content p-a20 bg-gray">To determine which is right for your application consider what your main priorities are – ongoing replacement costs, high efficiency, electronic?  A washable filter can easily be washed with water or blown out with compressed air but if your team forget this critical maintenance function, the filter loses its effectiveness. Throwaway filters, on the other hand, trap particles and hold them which eventually makes the filter so efficient it won’t pass air.</div>
                                                </div>
                                            </div>
                                            <div className="panel wt-panel">
                                                <div className="acod-head">
                                                    <h3 className="acod-title">
                                                        <a data-toggle="collapse" href="#collapseFive8" className="collapsed" data-parent="#accordion5">
                                                        How far away should the moveable extraction duct be?
                      <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h3>
                                                </div>
                                                <div id="collapseFive8" className="acod-body collapse">
                                                    <div className="acod-content p-a20 bg-gray">To work this out, first note the dimensions of the ‘face’ or opening on the extraction duct. Take the shortest dimension (typically about 300mm). The extraction should be positioned about 1-2 times the diameter of the hood, vertically above the welding point. If it’s much closer, the welder will often have problems seeing past the hood. If it’s too far away (over 2x diameter) then fume will tend to disperse and a large proportion of the fume will not be captured. The unit loses 90% of its face velocity at one diameter out. For example, with a 12” hood suction speed is 2000 ft/min but add a distance of 12” from the welding arc and the suction speed diminishes to 200 ft/min.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="faq-sidebar">

                                            <div className="wt-box p-a30 need-help site-bg-primary text-white m-b30">
                                                <h2 className="m-t0">Need any help!</h2>
                                                <p>Find answers to more frequently asked questions about Enviroflex, contacts and general customer account information </p>
                                                <NavLink to={"/contactus"} className="site-button-secondry site-btn-effect">Contact Us</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FAQ SECTION END */}
                </div>

                <Footer />

            </>
        );
    };
};

export default Home3;