import React from 'react';
import Header from './../Parts/Header10';
import Footer from './../Parts/Footer3';
import Banner from './../Segments/Banner';
import SectorDetails2 from './../Segments/SectorDetails2';

var bnrimg = require('./../../images/banner/S_2.jpg');

class Sector_Bench_Welding extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="Bench Welding" pagename="Bench Welding" bgimage={bnrimg.default}/>
                    {/* INNER PAGE BANNER END */}
                    <SectorDetails2 />
                </div>
                <Footer />
            </>
        );
    };
};

export default Sector_Bench_Welding;