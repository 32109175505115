import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Parts/Header10';
import Footer from './../Parts/Footer3';
import Banner from './../Segments/Banner';
import PageSidebar from './../Segments/PageSidebar';

var bnrimg = require('./../../images/banner/5.jpg');

class Difference extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner title="EDU 01" pagename="Reducing exposure to welding fumes" bgimage={bnrimg.default} />
                    {/* INNER PAGE BANNER END */}
                    {/* OUR BLOG START */}
                    <div className="section-full  p-t80 p-b50 bg-white">
                        <div className="container">
                            {/* BLOG SECTION START */}
                            <div className="section-content">
                                <div className="row d-flex justify-content-center">
                                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 m-b30">
                                        {/* BLOG START */}
                                        <div className="blog-post date-style-2 blog-lg">
                                            <div className="wt-post-media wt-img-effect zoom-slow">
                                                <NavLink to={"#"}><img src={require('./../../images/blog/default/thum2.jpg').default} alt="" /></NavLink>
                                            </div>
                                            <div className="wt-post-info  bg-white p-t30">
                                                <div className="wt-post-meta ">
                                                    <ul>
                                                        <li className="post-category"><span>Education</span> </li>
                                                        <li className="post-date">February 18, 2022</li>
                                                    </ul>
                                                </div>
                                                <div className="wt-post-title ">
                                                    <h2 className="post-title">Reducing exposure to welding fumes</h2>
                                                </div>
                                                <div className="wt-post-text">
                                                    <p>The welding process produces visible smoke that contains harmful metal fume and gas by-products.</p>
                                                    <p>Welding is still one of the most common industrial activities, but exposure to welding fume and other gases associated with welding can cause a variety of health problems.</p>
                                                    <p>Welders should understand the hazards of the materials they are working with and OSHA requires employers to provide information and training for workers on hazardous materials in the workplace.</p>
                                                    <p>Welding surfaces should be cleaned of any coating that could potentially create toxic exposure, such as solvent residue and paint</p>
                                                    <p>Workers should position themselves to avoid breathing welding fume and gases. For example, workers should stay upwind when welding in open or outdoor environments.</p>
                                                    <p>General ventilation, the natural or forced movement of fresh air, can reduce fume and gas levels in the work area. Welding outdoors or in open work spaces does not guarantee adequate ventilation. In work areas without ventilation and exhaust systems, welders should use natural drafts along with proper positioning to keep fume and gases away from themselves and other workers</p>
                                                    <p>Local exhaust ventilation systems can be used to remove fume and gases from the welder’s breathing zone. Keep fume hoods, fume extractor guns and vacuum nozzles close to the plume source to remove the maximum amount of fume and gases. Portable or flexible exhaust systems can be positioned so that fume and gases are drawn away from the welder. Keep exhaust ports away from other workers.</p>
                                                    <p>Remember, respiratory protection may be required if work practices and ventilation do not reduce exposures to safe levels.</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* BLOG END */}
                                    </div>
                                    {/* SIDE BAR START */}
                                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 rightSidebar  m-b30">
                                        <PageSidebar />
                                    </div>
                                    {/* SIDE BAR END */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* OUR BLOG END */}
                </div>
                <Footer />
            </>
        );
    };
};

export default Difference;